import debounce from "lodash.debounce"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import AdvancedSearch, { countSearchParams, getSearchFields } from "@/_components/AdvancedSearch"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"

import { loc } from "@/_services/localization"
import { isOffline } from "@/_services/offlineService"
import { getOptions } from "@/_services/userConfiguration"
import { exportToExcel, getEntities, mergeQueryParams, pseudoSelectOptionClassName, searchParamToObject } from "@/_services/utils"

import sassVars from "@/_styles/sass-vars.module.scss"

/**
 * @prop {boolean}  advancedSearch.hidden Hide advanced search icon if true Default to false.
 */
class SearchRowComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    const { entityName } = this.props

    this.state = {
      isExportingToExcel: false,
      showAdvancedSearch: false,
      hideAutocomplete: props.hideAutocomplete === undefined ? getOptions("hideAutocomplete") : props.hideAutocomplete,
      showSearchHint: false,
      autocompleteResults: [],
      xsBreakpoint: parseInt(sassVars.breakpointXs.replace("px", "")),
      selectedOptionIndex: null,
      searchInputId: `search-input-${entityName}`,
      isTableConfigurationBtnActive: false,
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyboardShortcuts, false)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyboardShortcuts, false)
  }

  handleKeyboardShortcuts = event => {
    const { advancedSearch } = this.props
    const { showAdvancedSearch } = this.state
    const { keyCode, altKey: ALT } = event

    const Q = keyCode === 81
    if (ALT && Q && !advancedSearch?.hidden) {
      this.setState({ showAdvancedSearch: !showAdvancedSearch, isTableConfigurationBtnActive: false })
    }
  }

  handleNavigate = ({ event, isArrowUp, isArrowDown }) => {
    event.preventDefault()
    const { selectedOptionIndex, autocompleteResults, showAutocompleteResults } = this.state
    const selectionIndex = selectedOptionIndex ?? -1
    const activeIndex = (selectionIndex + (isArrowDown ? 1 : autocompleteResults.length - 1)) % autocompleteResults.length

    if (!showAutocompleteResults) this.setState({ showAutocompleteResults: true })

    if (isArrowUp || isArrowDown) {
      const options = document.getElementsByClassName(pseudoSelectOptionClassName)
      options[activeIndex]?.scrollIntoView({ block: "nearest" })
    }

    this.setState({ selectedOptionIndex: activeIndex })
  }

  handleSetFilter = async ({ filter }) => {
    const { showSearchHint } = this.state
    const { location, history, filterName = "filter" } = this.props

    let patch

    // Clearing
    if (filter === null) {
      patch = { filter: undefined, search: undefined, page: 1 }
    }
    // Choosing from autocomplete results
    else if (filter?.startsWith("AUTOCOMPLETE;")) {
      filter = filter.replace("AUTOCOMPLETE;", "")
      patch = { filter, search: filter, page: 1 }
    }
    // Filtering
    else {
      patch = { [filterName]: filter, page: 1 }
    }

    const queryParams = mergeQueryParams(location.search, patch)
    history.replace(`${location.pathname}${queryParams}`)
    if (!showSearchHint) this.setState({ showSearchHint: true })
  }

  handleClear = async () => {
    const { searchInputId } = this.state
    const { location, history, filterName = "filter" } = this.props

    const queryParams = mergeQueryParams(location.search, { [filterName]: undefined, search: undefined })
    history.replace(`${location.pathname}${queryParams}`)

    document.getElementsByClassName(searchInputId)[0].focus()
  }

  handleExportXlsx = async () => {
    const { location, exportData } = this.props
    this.setState({ isExportingToExcel: true })

    // Merge url query with "exportData.url" query
    if (exportData.url && location.search) {
      if (exportData.url.includes("?")) {
        const [url, queryString] = exportData.url.split("?")
        const newQueryString = mergeQueryParams(location.search, searchParamToObject(queryString))
        exportData.url = `${url}${newQueryString}`
      } else {
        exportData.url += location.search
      }
    }

    try {
      await exportToExcel(exportData)
    } finally {
      this.setState({ isExportingToExcel: false })
    }
  }

  // On enter we only update the ?search query param
  // and in the parent component the componentDidUpdate() will listen to the change and re-fetch the entities
  handleEnter = async search => {
    const { location, history } = this.props

    const queryParams = mergeQueryParams(location.search, { search: search?.trim() ? search.trim() : undefined })
    history.replace(`${location.pathname}${queryParams}`)
    this.setState({ showSearchHint: false, showAutocompleteResults: false })
  }

  handleAutocompleteSync = async ({ filter }) => {
    const { hideAutocomplete } = this.state
    if (hideAutocomplete) return

    const { location, entityName, filterName = "filter" } = this.props
    const queryParams = searchParamToObject(location.search)
    if (!filter) {
      if (queryParams.filter) filter = queryParams[filterName]
    } else {
      filter = filter.trim()
    }

    this.handleSetFilter({ filter })

    if (entityName && filter?.length > 2) {
      const results = await getEntities(entityName, { ...queryParams, autocomplete: filter, search: undefined })

      // Add the user filter as 1st option in results
      if (filter && !results.map(r => r.toLowerCase()).includes(filter.toLowerCase())) results.unshift(filter)

      // ["v1", "v2", ...] => [{ value: "v1", label: "v1" }, { value: "v2", label: "v2" }, ...]
      // Add "AUTOCOMPLETE;" to differentiate between typing and selection
      if (results.length) {
        this.setState({
          autocompleteResults: results.map(value => ({ value: `AUTOCOMPLETE;${value}`, label: value })),
          showAutocompleteResults: true,
        })
      }
    }
  }

  handleAutocomplete = debounce(this.handleAutocompleteSync, 250)

  handleKeyDown = event => {
    const pressedKey = event?.key?.toLowerCase()
    const isArrowDown = pressedKey === "arrowdown"
    const isArrowUp = pressedKey === "arrowup"
    const { value } = event?.target || {}
    if (pressedKey === "backspace" && value?.length === 1) this.handleSetFilter({ filter: null })
    if ((isArrowUp || isArrowDown) && value?.length > 0) {
      this.handleNavigate({ event, isArrowUp, isArrowDown })
    }
    if (pressedKey === "enter") {
      if (value) {
        this.handleSelection({ value })
      } else {
        this.handleEnter() // Reset the ?search results
      }
    }
  }

  handleSelection = ({ autocompleteResult, value }) => {
    const { filterName = "filter", location } = this.props
    const { autocompleteResults, selectedOptionIndex } = this.state
    const result = autocompleteResult ?? autocompleteResults[selectedOptionIndex]
    if (result) {
      this.handleSetFilter({ filter: result.value })
      // we update the key to reload the component and we set callback to hide results
      this.setState({ key: Math.random() }, () => this.setState({ showAutocompleteResults: false }))
      return
    }

    if (value) {
      this.handleEnter(value)
      return
    }

    const queryParams = searchParamToObject(location.search)
    const filter = queryParams[filterName] || ""
    if (filter) this.handleEnter(filter)
  }

  onSearchInputFocus = () => {
    const { showSearchHint, showAutocompleteResults } = this.state
    const statePatch = {}
    if (!showSearchHint) statePatch.showSearchHint = true
    if (!showAutocompleteResults) statePatch.showAutocompleteResults = true
    this.setState(statePatch)
  }

  onSearchInputBlur = () => {
    const { showSearchHint, showAutocompleteResults } = this.state
    const statePatch = {}
    if (showSearchHint) statePatch.showSearchHint = false
    if (showAutocompleteResults) statePatch.showAutocompleteResults = false
    // we need this timeout otherwise clicking on the hint would hide the hint before the search is launched
    setTimeout(() => this.setState(statePatch), 200)
  }

  handleShowAdvancedSearch = () => {
    const { toggleTableConfiguration } = this.props
    const { showAdvancedSearch } = this.state
    this.setState({ showAdvancedSearch: !showAdvancedSearch, isTableConfigurationBtnActive: false })
    if (toggleTableConfiguration) toggleTableConfiguration({ show: false })
  }

  handleShowTableConfiguration = () => {
    const { toggleTableConfiguration } = this.props
    const { isTableConfigurationBtnActive } = this.state
    this.setState({ isTableConfigurationBtnActive: !isTableConfigurationBtnActive, showAdvancedSearch: false })
    if (toggleTableConfiguration) toggleTableConfiguration({ show: !isTableConfigurationBtnActive })
  }

  render() {
    const {
      isExportingToExcel,
      showAdvancedSearch,
      hideAutocomplete,
      showSearchHint,
      showAutocompleteResults,
      autocompleteResults,
      xsBreakpoint,
      selectedOptionIndex,
      key,
      searchInputId,
      isTableConfigurationBtnActive,
    } = this.state
    let {
      children,
      loading,
      location,
      exportData,
      entityName,
      getEntities,
      placeholder = loc`Search...`,
      searchFields,
      toggleButtonClassName,
      onToggleButtonClick,
      filterName = "filter",
      advancedSearch,
      showTableConfigurationBtn = true,
      advancedSearchInModal,
    } = this.props
    const queryParams = searchParamToObject(location.search)
    const filter = queryParams[filterName] || ""
    const search = queryParams["search"] || ""
    const _searchFields = getSearchFields({ fields: searchFields, entityName, advancedSearch })
    const searchParamsNb = countSearchParams(queryParams, _searchFields)
    const showClearInputButton = filter || search

    return (
      <Row className="search-row-component mb-theme">
        <Col xs={12} sm={6} md={5} lg={3} className="pseudo-select-control">
          {/* this div is necessary to keep a constant height between themes */}
          <div className="relative">
            <div className={`w-100${showClearInputButton ? " input-group" : ""}`}>
              <div className="search-input-icon pdr-0 m-0 min-w-0 absolute z-10 bg-white top-1px">
                <CustomButton
                  tabIndex="-1" // avoid focus on this element as it is only a visual clue
                  bsStyle="default"
                  iconClassName="icn-search icn-sm"
                  className="pd-0 m-0 text-gray-darker min-w-0 inline-flex-center b-0 c-default"
                />
              </div>

              <FormInput
                key={key}
                inArray
                showCopy
                debounce
                // This value must match sass-vars.css xs breakpoint.
                // In mobile we don't want autofocus as it is annoying when not immediately relevant for the current navigation.
                autoFocus={window.innerWidth > xsBreakpoint}
                onFocus={this.onSearchInputFocus}
                onBlur={this.onSearchInputBlur}
                hideChevron
                field="filter"
                fcClassName={`${searchInputId} br-theme`}
                value={filter || search}
                openMenuOnClick={false}
                placeholder={placeholder}
                noOptionsMessage={() => null}
                loadingMessage={() => loc("Loading")}
                onKeyDown={this.handleKeyDown}
                onSetState={hideAutocomplete ? this.handleSetFilter : this.handleAutocomplete}
              />

              {/* TODO: Delete this block once autocomplete has been generalized. It is almost the same block as the autocomplete one. */}
              {hideAutocomplete && showSearchHint && filter && (
                <div className="pseudo-select-options">
                  <div onMouseDown={() => this.handleEnter(filter)} className={`leading-1-5 ${pseudoSelectOptionClassName}`} data-has-icon="true">
                    <span className="flex-center">
                      <i className="icn-search icn-sm text-gray-darker" />
                      <span className="pdl-5px text-black">{filter}</span>
                    </span>
                    <span className="pdl-5px text-black font-1-2">{loc(`Tap ENTER for more results`)}</span>
                  </div>
                </div>
              )}

              {!hideAutocomplete && showAutocompleteResults && autocompleteResults.length > 0 && filter && (
                <div className="pseudo-select-options">
                  {autocompleteResults.map((autocompleteResult, index) => {
                    // WARNING: we absolutely must use onMouseDown and not onClick
                    // because the browser might randomly prioritize the onBlur event of the accompanying form input,
                    // making this block disappear thus never trigerring the click event.
                    // And adding a setTimeout in the blur event handler has been tried and often fails so this is not a good solution.
                    return (
                      <div
                        key={index}
                        className={`leading-1-5 ${pseudoSelectOptionClassName} ${index === selectedOptionIndex ? "selected-option" : ""}`}
                        data-has-icon="true"
                        onMouseDown={() => this.handleSelection({ autocompleteResult })}
                        aria-label={autocompleteResult.label}
                      >
                        <span className="flex-center">
                          <i className="icn-search icn-sm text-gray-darker" />
                          <span className="pdl-5px text-black">{autocompleteResult.label}</span>
                        </span>
                        {index === 0 && <span className="pdl-5px text-black font-1-2">{loc(`Tap ENTER for more results`)}</span>}
                      </div>
                    )
                  })}
                </div>
              )}

              {showClearInputButton && (
                <div className="pseudo-select-addons" onClick={this.handleClear}>
                  <ButtonWithTooltip
                    bsStyle="default"
                    disabled={loading}
                    className="icn-xmark icn-xs flex-center"
                    btnClassName="m-0 min-w-0 text-gray-darker flex-center"
                    onClick={this.handleClear}
                    tooltip={loc("Clear")}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>

        {!isOffline() && (
          <Col xs={12} sm={6} lg={7} className="flex">
            {searchFields && !advancedSearch?.hidden && (
              <ButtonWithTooltip
                bsStyle="default"
                className={`icn-search-filter ${showAdvancedSearch ? "icn-rotate-180 text-info" : ""}`}
                btnClassName="mr-0 flex-center"
                data-test="search-entities-btn"
                onClick={this.handleShowAdvancedSearch}
                disabled={loading}
                tooltip={loc("Toggle search filters") + " (ALT + Q)"}
                statusIndicator={searchParamsNb ? "info" : ""}
              />
            )}

            {showTableConfigurationBtn && (
              <ButtonWithTooltip
                bsStyle="default"
                disabled={loading}
                btnClassName="mr-0 flex-center"
                className={`icn-sliders ${isTableConfigurationBtnActive ? "text-info" : ""}`}
                onClick={this.handleShowTableConfiguration}
                tooltip={loc("Table configuration")}
              />
            )}

            {onToggleButtonClick && (
              <ButtonWithTooltip
                bsStyle="default"
                disabled={loading}
                btnClassName="mr-0 flex-center"
                className={toggleButtonClassName}
                onClick={onToggleButtonClick}
                tooltip={loc("Toggle view")}
              />
            )}

            {exportData && !exportData?.hidden && (
              <ButtonWithTooltip
                bsStyle="default"
                className={`${isExportingToExcel ? "icn-circle-notch icn-spin" : "icn-excel-download"}`}
                onClick={this.handleExportXlsx}
                btnClassName="mr-0 flex-center"
                disabled={isExportingToExcel || loading}
                tooltip={loc("Download data as Excel file")}
              />
            )}

            {getEntities && (
              <ButtonWithTooltip
                bsStyle="default"
                btnClassName="mr-0 flex-center"
                disabled={loading}
                className={`icn-reload ${loading ? "icn-spin" : ""}`}
                onClick={() => getEntities()}
                tooltip={loc("Reload data") + " (ALT + R)"}
              />
            )}

            {children}
          </Col>
        )}

        {searchFields && !advancedSearch?.hidden && (
          <Col xs={12}>
            <AdvancedSearch
              show={showAdvancedSearch}
              advancedSearchInModal={advancedSearchInModal}
              loading={loading}
              search={getEntities}
              fields={searchFields}
              entityName={entityName}
              advancedSearch={advancedSearch}
              close={() => this.setState({ showAdvancedSearch: false })}
            />
          </Col>
        )}
      </Row>
    )
  }
}

export default withRouter(SearchRowComponent)
