import axios from "axios"
import { getFilterFunction } from "basikon-common-utils"
import React, { createRef } from "react"
import { Col, Grid, Row } from "react-bootstrap"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import LayoutCard from "@/_components/LayoutCard"
import Table, { mergeColumns } from "@/_components/Table"

import { getUriAndCacheResponse } from "@/_services/cache"
import { copyEntities, pasteEntities } from "@/_services/copyPaste"
import { getEntityDisplay } from "@/_services/entity"
import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addNotification } from "@/_services/notification"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import { getClientRouteKey, getEntities, hasQueryParamsChanged, mergeQueryParams, searchParamToObject } from "@/_services/utils"

class ProductsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "ProductsPage"
    const pageConfig = getPageConfig(pageConfigKey)
    const defaultColumns = [
      { title: "Registration", name: "registration", linkTo: "/product/{registration}" },
      { title: "Status", name: "status", select: "productStatus", badge: true },
      { title: "Contract type", name: "contractType", select: "contractType" },
      { title: "Code", name: "code", select: "financialProduct" },
      { title: "Name", name: "name" },
      { title: "Start date", name: "startDate", type: "date" },
      { title: "End date", name: "endDate", type: "date" },
    ]
    const columns = mergeColumns(defaultColumns, pageConfig?.columns)

    this.state = {
      products: [],
      loading: false,
      selectedEntities: new Set(),
      pageConfig,
      pinnedData: [],
      entityName: "Product",
      columns,
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    this.getProducts()

    getList("productStatus", () => this.setState({ loaded: true }))
  }

  componentDidUpdate(prevProps) {
    if (hasQueryParamsChanged(this.props, prevProps)) this.getProducts()
  }

  getProducts = async params => {
    const { entityName } = this.state
    const { location, history } = this.props
    let queryParams = { ...searchParamToObject(location.search), ...params }

    this.setState({ loading: true })
    const [products, pinnedData] = await Promise.all([getEntities("product", queryParams), fetchPinnedEntities({ entityName, queryParams })])
    this.setState({ products, pinnedData: this.formatTableData(pinnedData), loading: false })

    if (params) {
      this.preventAutoRefresh.current = true
      queryParams.page = 1
      queryParams = mergeQueryParams(location.search, queryParams)
      history.replace(`/products${queryParams}`)
      this.preventAutoRefresh.current = false
    }
  }

  handleSelection = product => {
    let { selectedEntities } = this.state

    if (selectedEntities.has(product.registration)) selectedEntities.delete(product.registration)
    else selectedEntities.add(product.registration)

    this.setState({ selectedEntities })
  }

  handlePaste = async () => {
    const { products } = this.state
    const { schema } = await getUriAndCacheResponse("/api/financing/products-schema")
    try {
      this.setState({
        products: [...(await axios.post("/api/financing/products", await pasteEntities(schema))).data.reverse(), ...products],
      })
    } catch (error) {
      console.log("Paste error is", error)
      return addNotification("Invalid data", "warning")
    }
  }

  handleCopy = () => {
    const { products, selectedEntities } = this.state
    copyEntities(products.filter(it => selectedEntities.has(it.registration)))
    addNotification("Done")
  }

  formatTableData = data => {
    return data.map(it => ({ ...it, id: it.registration }))
  }

  render() {
    const { products = [], loading, selectedEntities, pageConfig, entityName, pinnedData, columns } = this.state
    const { location, history } = this.props

    const { filter = "" } = searchParamToObject(location.search)
    const filteredProducts = filter?.trim() ? filterProducts(products, filter) : products

    const action = (
      <>
        <CustomButton
          fill
          pullRight
          bsSize="small"
          bsStyle="primary"
          className="inline-flex-center"
          hidden={pageConfig?.addButton?.hidden}
          onClick={() => history.push(pageConfig?.addButton?.linkTo || "/product")}
        >
          <i className="icn-plus icn-xs mr-5px" />
          {loc(pageConfig?.addButton?.label || "Add")}
        </CustomButton>

        {pageConfig?.showCopyPasteButton && (
          <>
            <ButtonWithTooltip
              className="icn-paste icn-sm"
              btnClassName="flex-center"
              bsStyle="primary"
              simple={false}
              bsSize="xs"
              pullRight
              onClick={this.handlePaste}
            />

            {selectedEntities.size > 0 && (
              <ButtonWithTooltip
                className="icn-copy icn-sm"
                btnClassName="flex-center"
                bsStyle="primary"
                simple={false}
                bsSize="xs"
                pullRight
                onClick={this.handleCopy}
              />
            )}
          </>
        )}
      </>
    )

    const title = loc(pageConfig?.title || "Products")

    const data = this.formatTableData(filteredProducts)

    return (
      <Grid className="products-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "products-kpi", noCard: false } }]} />
        )}

        <Card title={title} action={action}>
          <Table
            useSearchRowComponent
            exportDataFileName={title}
            getEntities={this.getProducts}
            searchFields={productSearchFields}
            data={data}
            pinnedData={pinnedData}
            entityName={entityName}
            columns={columns}
            loading={loading}
            selectedEntities={selectedEntities}
            onRowClick={pageConfig?.showCopyPasteButton && this.handleSelection}
            pageConfig={pageConfig}
          />

          <Row>
            <Col xs={12} className="mt-theme">
              <CustomButton bsStyle="primary" bsSize="xs" pullRight onClick={() => history.push("/product-tables")} label="Product tables" />
            </Col>
          </Row>
        </Card>
      </Grid>
    )
  }
}

export default ProductsPage

export const productSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "status", type: "multiple", select: "productStatus", colProps: { xs: 12, sm: 4 } },
  ],
]

export function filterProducts(products, filter) {
  if (!filter) return products
  const includes = getFilterFunction(filter, getLocale())

  return products.filter(product => {
    const statusLabel = getLabel("productStatus", product.status)

    return (
      includes(statusLabel) ||
      includes(product.code) ||
      includes(product.name) ||
      includes(product.rate) ||
      includes(product.status) ||
      includes(product.endDate) ||
      includes(product.startDate) ||
      includes(product.reference) ||
      includes(product.allocation) ||
      includes(product.registration) ||
      includes(product.fundRegistration) ||
      includes(getEntityDisplay(product)) ||
      includes(product.contractRegistration)
    )
  })
}
